/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { LayerContentElementV1 } from '@volkswagen-onehub/layer-manager';
import { ALERT, LayerTypes } from './globals';
import OneLayerInner, { Animation, AnimationWrapper, ScrollableContent } from './one-layer-inner';
import Close from './close';
import OneLayerContent from './one-layer-content';
import OneLayerOuter from './one-layer-outer';
import { clearOneLayerQuery } from './util';

export interface IOneLayer {
  showCloseButton?: boolean;
  close: () => void;
  type: LayerTypes;
  children: LayerContentElementV1;
  zIndexAlert: number;
  zIndex?: number;
  state?: unknown;
  active: boolean;
  setStartHideAnimation: (start: boolean) => void;
  hideAnimation: boolean;
  openAnimation: boolean;
  showAnimation: boolean;
  setScrollOffset: (id: string, n: number) => void;
  id: string;
  scrollOffset?: number;
  index: number;
  layerLength: number;
  userCloseable?: boolean;
  invisible: boolean;
}

export const OneLayer: React.FC<IOneLayer> = ({
  close,
  type,
  children,
  showCloseButton,
  zIndexAlert,
  zIndex,
  state,
  setStartHideAnimation,
  hideAnimation,
  openAnimation,
  showAnimation,
  active,
  setScrollOffset,
  id,
  scrollOffset,
  index,
  layerLength,
  userCloseable,
  invisible
}) => {
  const refOuter = React.useRef<HTMLDialogElement | null>(null);
  const refFirstFocusable = React.useRef<HTMLElement | null>(null);
  const refLastFocusable = React.useRef<HTMLElement | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const layerContentContainerRef = useRef(null);

  const [initialFocusedElement, setInitialFocusedElement] = React.useState<HTMLElement | null>(
    null
  );

  // Effect to store the initially focused element when the modal opens
  React.useEffect(() => {
    setInitialFocusedElement(document.activeElement as HTMLElement);
  }, [active]);

  // Function to return focus to the initially focused element
  const returnFocusToInitial = () => {
    if (initialFocusedElement) {
      initialFocusedElement.focus();
    }
  };

  useEffect(() => {
    const refCopy = ref.current;

    const listener = debounce((): void => {
      // @ts-ignore
      setScrollOffset(id, refCopy?.scrollTop as number);
    }, 250);

    if (active && refCopy) {
      // @ts-ignore
      refCopy.addEventListener('scroll', listener);
    }

    // Multiple layers - keep focus on current layer when other layers are opened/closed
    // @ts-ignore
    const current = ref?.current.querySelector('.layer-content');
    (current as HTMLElement)?.focus();

    return (): void => {
      if (active && refCopy) {
        // @ts-ignore
        refCopy.removeEventListener('scroll', listener);
      }
    };
  }, [ref, active]);

  useEffect(() => {
    // @ts-ignore
    refOuter.current?.focus();
    // @ts-ignore
    ref.current.scrollTop = scrollOffset;
    if (
      children &&
      typeof children === 'function' &&
      // @ts-ignore
      layerContentContainerRef.current instanceof HTMLElement
    ) {
      children(layerContentContainerRef.current);
    }

    // @ts-ignore
    layerContentContainerRef.current?.setAttribute('tabindex', '0');
  }, []);

  const onLayerClickInner = useCallback((e: { stopPropagation: () => void }): void => {
    // eslint-disable-next-line
    e.stopPropagation();
  }, []);

  const onLayerClickOuter = useCallback(
    (e: { stopPropagation: () => void }): void => {
      if (!userCloseable && userCloseable !== undefined) {
        return;
      }
      clearOneLayerQuery(layerLength);
      // eslint-disable-next-line
      e.stopPropagation();
      setStartHideAnimation(true);
      returnFocusToInitial();
    },
    [setStartHideAnimation, returnFocusToInitial]
  );

  const handleTabKey = (e: React.KeyboardEvent) => {
    const focusableElements = Array.from<HTMLElement>(
      refOuter.current?.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input:not([disabled])'
      ) ?? []
    );

    [refFirstFocusable.current] = focusableElements;
    refLastFocusable.current = focusableElements[focusableElements.length - 1];

    if (document.activeElement === refLastFocusable.current && e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      refFirstFocusable.current?.focus();
    }
    if (document.activeElement === refFirstFocusable.current && e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      refLastFocusable.current?.focus();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEscapeKey = (_e: React.KeyboardEvent) => {
    if (userCloseable || userCloseable === undefined) {
      clearOneLayerQuery(layerLength);
      setStartHideAnimation(true);
      returnFocusToInitial();
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent) => {
    const targetElement = e.target as HTMLElement;

    const isButton =
      targetElement.nodeName === 'BUTTON' && targetElement.getAttribute('type') === 'button';

    if (isButton) {
      e.preventDefault();

      // close the layer and focus on initial element
      if (userCloseable || userCloseable === undefined) {
        clearOneLayerQuery(layerLength);
        setStartHideAnimation(true);
        returnFocusToInitial();
      }
    }
  };

  const handleArrowKeys = (e: React.KeyboardEvent) => {
    e.preventDefault(); // Prevent default scrolling behavior
    const delta = e.key === 'ArrowUp' ? -30 : 30; // Adjust the scrolling amount as needed

    // Adjust scrollTop of the layer
    if (ref.current) {
      ref.current.scrollTop += delta;
    }
  };

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.key) {
        case 'Tab':
          handleTabKey(e);
          break;
        case 'Escape':
          handleEscapeKey(e);
          break;
        case 'Enter':
          handleEnterKey(e);
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          handleArrowKeys(e);
          break;
        default:
        // Handle other keys if needed
      }
    },
    [setStartHideAnimation, returnFocusToInitial]
  );

  return (
    <OneLayerOuter
      data-fefa-layer-active="true"
      data-one-layer="true"
      data-testid={`one-layer-outer-${index}`}
      // cannot close ALERT layer when clicking outer shadow
      onClick={type !== ALERT ? onLayerClickOuter : undefined}
      type={type}
      zIndex={type === ALERT ? zIndexAlert : zIndex}
      invisible={invisible}
    >
      <AnimationWrapper type={type}>
        <Animation
          className={openAnimation ? 'oneLayerOpenAnimation' : ''}
          data-testid="open-animation-wrapper"
        >
          <Animation
            className={showAnimation ? 'oneLayerShowAnimation' : ''}
            data-testid="show-animation-wrapper"
          >
            <Animation
              className={hideAnimation ? 'oneLayerHideAnimation' : ''}
              data-testid="hide-animation-wrapper"
              onAnimationEnd={(): void => {
                if (hideAnimation) {
                  setStartHideAnimation(false);
                  if (active) {
                    close();
                  }
                }
              }}
            >
              <OneLayerInner
                data-testid="one-layer-inner"
                onClick={onLayerClickInner}
                onMouseDown={onLayerClickInner}
                onTouchStart={onLayerClickInner}
                type={type}
                onKeyDown={onKeyDown}
                // @ts-ignore
                ref={refOuter}
                role={type === ALERT ? 'alertdialog' : 'dialog'}
                aria-modal="true"
              >
                <ScrollableContent
                  data-scroll-context
                  data-testid="one-layer-scrollable-content"
                  ref={ref}
                >
                  <OneLayerContent
                    data-testid={`one-layer-content-${index}`}
                    ref={layerContentContainerRef}
                    type={type}
                    className="layer-content"
                    // @ts-ignore
                    hasPadding={state?.hasPadding}
                  >
                    {typeof children !== 'function' ? children : null}
                  </OneLayerContent>
                </ScrollableContent>
                {showCloseButton && (
                  // @ts-ignore
                  <Close onClose={onLayerClickOuter} type={type} icon={state?.icon} />
                )}
              </OneLayerInner>
            </Animation>
          </Animation>
        </Animation>
      </AnimationWrapper>
    </OneLayerOuter>
  );
};
